import React, {Component} from "react";
import styled from "styled-components";
import Icon from "ui/Icon";
import IconBackdrop from "ui/Icon/IconBackdrop";

export const MedicareAdvantageWrapper = styled.div`
	text-align: center;
`;

export const MedicareAdvantageTable = styled.div`
	display: flex;
	font-size: 16px;
	margin-top: 30px;
	font-weight: bold;
`;

export const SpacedText = styled.div`
	margin-top: 25px;

	> * {
		line-height: 40px;
	}
`;

export const Header = styled.span`
	font-weight: bold;
	font-size: 31px;

	span {
		border-bottom: 3px solid var(--dark-mint);
		padding-bottom: 5px;
	}
`;

export const BottomBar = styled.div`
	margin: 50px -9999rem 0 -9999rem;
	padding: 0.25rem 9999rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--dark-mint);
	height: 100px;

	div {
		margin: 0 20px;
	}
`;

const MedAdvColumnStyled = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	> :nth-child(even) {
		background-color: white;
	}
	> :nth-child(odd) {
		background-color: var(--mint);
	}

	:last-child > * {
		border: none;
	}
`;

const MedAdvCellStyled = styled.div`
	height: 130px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 2px solid var(--dark-mint);

	:first-child {
		border: none;
	}
`;

const MedAdvHeaderCellStyled = styled(MedAdvCellStyled)`
	flex-direction: column;
`;

const LoadingStyled = styled.h2`
	a: 1;
`;

const ProviderStyled = styled.div`
	font-weight: ${({$covered}) => ($covered ? "bold" : "normal")};
	text-align: left;

	span {
		margin-left: 15px;
	}
`;

const IconRow = styled.div`
	img {
		margin-right: 35px;
	}

	img:last-child {
		margin: 0;
	}
`;

const NormalWeight = styled.div`
	font-weight: normal;
`;

const Provider = ({d}) => {
	return (
		<ProviderStyled $covered={d.covered}>
			<Icon src={d.covered ? "checkmark-pointy" : "x"} width={20} height={20} />
			<span>{d.name}</span>
		</ProviderStyled>
	);
};

const rowHeaders = [
	{src: "pig", title: "Monthly premiums"},
	{src: "provider", title: "In-network (If applicable)"},
	{src: "formulary", title: "Prescriptions on drug list", width: 38},
	{src: "house", title: "Referrals & care sites"},
	{src: "star", title: "Available in plan"},
];

export class Loading extends Component {
	state = {numPeriods: 3};

	componentDidMount() {
		this.intervalId = window.setInterval(() => {
			const {numPeriods} = this.state;
			this.setState({numPeriods: numPeriods + 1});
		}, 500);
	}

	componentWillUnmount() {
		window.clearInterval(this.intervalId);
	}

	// TODO: revisit the logic here. I couldn't figure out this React bug for the life of me.
	// Don't have numPeriods continue to grow - I should do the % in the setState
	render() {
		const {numPeriods} = this.state;

		const text = `Finding the best plans for you${".".repeat(
			(numPeriods % 3) + 1
		)}`;
		return <LoadingStyled children={text} />;
	}
}

export const MedicareAdvantageRowHeaders = (props) => {
	return (
		<MedAdvColumnStyled>
			<MedAdvCellStyled />
			{rowHeaders.map((h, i) => (
				<MedAdvHeaderCellStyled key={i}>
					<IconBackdrop color="#FFA59F" width={64}>
						<Icon src={h.src} width={h.width || 50} />
					</IconBackdrop>
					<br />
					{h.title}
				</MedAdvHeaderCellStyled>
			))}
		</MedAdvColumnStyled>
	);
};

export const MedicareAdvantageSummaryColumn = ({plan, index}) => {
	const referrals = plan.referrals ? (
		<>
			<div>No referrals required</div>
			<br />
			<br />
			<NormalWeight>
				Can see out-of-network specialists at an extra cost
			</NormalWeight>
		</>
	) : (
		<>
			<b>
				Specialist vists <br />
				require a referral
			</b>
			<br />
			<br />
			<div>No out-of-network coverage</div>
		</>
	);

	const extraBenefits = [];
	if (plan.extraBenefits.vision) extraBenefits.push("eye");
	if (plan.extraBenefits.dental) extraBenefits.push("tooth");
	if (plan.extraBenefits.hearing) extraBenefits.push("ear");

	return (
		<MedAdvColumnStyled>
			<MedAdvCellStyled>
				<h2>{`Plan ${index + 1}`}</h2>
			</MedAdvCellStyled>
			<MedAdvCellStyled>
				<div>{`$${plan.premiums.min} - $${plan.premiums.max}`}</div>
			</MedAdvCellStyled>
			<MedAdvCellStyled>
				<div>
					{plan.doctors.map((d) => (
						<Provider key={d.name} d={d} />
					))}
				</div>
			</MedAdvCellStyled>
			<MedAdvCellStyled>
				<div
					children={`${plan.coveredPrescriptionCount} of ${plan.totalPrescriptionCount}`}
				/>
			</MedAdvCellStyled>
			<MedAdvCellStyled>
				<div children={referrals} />
			</MedAdvCellStyled>
			<MedAdvCellStyled>
				<IconRow>
					{extraBenefits.map((eb) => (
						<Icon src={eb} key={eb} />
					))}
				</IconRow>
			</MedAdvCellStyled>
		</MedAdvColumnStyled>
	);
};
