import React, {Component} from "react";
import Form from "ui/Form";
import TextInput from "ui/TextInput";
import RadioInput from "ui/RadioInput";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import endpoints from "util/constants/endpoints";
import {RadioLabel} from "./BasicInfoHelpers";

export class BasicInfo extends Component {
	nextComponent = "MedicareRetire";
	genderOptions = [
		{value: "male", label: "Male"},
		{value: "female", label: "Female"},
		{value: "na", label: "Prefer not to answer"},
	];

	constructor(props) {
		super(props);

		props.setMaxWidth(800);

		let savedState = localStorage.getItem(
			LOCAL_STORAGE_CONSTS.PAGES.BASIC_INFO
		);
		savedState = JSON.parse(savedState);

		if (savedState) {
			this.state = savedState;

			let formValid = false;
			try {
				formValid = this.isStateValid();
			} catch (e) {
				console.error(e);
			}

			if (formValid) {
				this.setParentPersistence();
				props.setNextComponent(this.nextComponent);
			}
		} else {
			this.state = {
				birthMonthYear: "",
				zipcode: "",
				email: "",
				gender: "",
			};
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {setNextComponent} = this.props;
		const {birthMonthYear, zipcode, email, gender} = this.state;

		let formValid = false;
		try {
			formValid = this.isStateValid();
		} catch (e) {}

		setNextComponent(formValid ? this.nextComponent : null);
		this.setParentPersistence();

		localStorage.setItem(
			LOCAL_STORAGE_CONSTS.PAGES.BASIC_INFO,
			JSON.stringify({birthMonthYear, zipcode, email, gender})
		);
	}

	isStateValid = () => {
		const {birthMonthYear, zipcode, email, gender} = this.state;

		return (
			this.isValidBirthMonthYear(birthMonthYear) &&
			this.isValidEmail(email) &&
			this.isValidZipcode(zipcode) &&
			gender
		);
	};

	setParentPersistence = () => {
		const {setPersistence} = this.props;
		const {birthMonthYear, zipcode, email} = this.state;

		setPersistence({
			url: endpoints.customerSave(),
			data: {
				email,
				birthmonth: birthMonthYear.substring(0, 2),
				birthyear: birthMonthYear.slice(-4),
				zip: zipcode,
			},
			onResponse: this.onResponse,
		});
	};

	isValidBirthMonthYear = (birthMonthYear) => {
		const month = parseInt(birthMonthYear.substring(0, 2));
		const year = parseInt(birthMonthYear.slice(-4));

		if (month < 1 || month > 12) throw new Error("Enter a valid birth month");
		if (year < 1900 || year > 2020) throw new Error("Enter a valid birth year");

		return true;
	};

	isValidEmail = (email) => {
		const emailMatchPattern = email.match(/.*@.*\..*/);

		if (!emailMatchPattern) throw new Error("Enter a valid email address");

		return true;
	};

	isValidZipcode = (zipcode) => {
		if (zipcode.length !== 5) throw new Error("Enter a valid zipcode");

		return true;
	};

	onResponse = (customerID) => {
		localStorage.setItem(LOCAL_STORAGE_CONSTS.CUSTOMER_ID, customerID);
	};

	onChangeBirthMonthYear = (val) => {
		const parsedVal = val.replace(/\D/g, "").substring(0, 6);

		this.setState({
			birthMonthYear: parsedVal,
		});
	};

	onChangeZipcode = (val) => {
		const parsedVal = val.replace(/\D/g, "").substring(0, 5);

		this.setState({
			zipcode: parsedVal,
		});
	};

	render() {
		const {advanceNavigation} = this.props;
		const {birthMonthYear, zipcode, email, gender} = this.state;

		const birthDisplay =
			birthMonthYear.length >= 2
				? `${birthMonthYear.substring(0, 2)} / ${birthMonthYear.substring(
						2,
						6
				  )}`
				: birthMonthYear;

		return (
			<div>
				<h2 className="page-header">How can we help today?</h2>
				<h4 className="page-header">
					To give you personalized advice, we need to <br />
					know a few more things about you.
				</h4>
				<Form onSubmit={advanceNavigation}>
					<div className="form-row">
						<TextInput
							value={birthDisplay}
							onChange={this.onChangeBirthMonthYear}
							placeholder="MM/YYYY"
							title="Birth Month/Year"
							type="tel"
							minLength="6"
							validator={this.isValidBirthMonthYear}
						/>
						<TextInput
							value={zipcode}
							onChange={this.onChangeZipcode}
							title="Zipcode"
							type="tel"
							minLength="5"
						/>
					</div>
					<div className="form-row">
						<TextInput
							value={email}
							onChange={(email) => this.setState({email})}
							placeholder="example@getchapter.com"
							title="Email address"
							type="email"
						/>
					</div>
					<div className="form-row">
						<RadioLabel>Your gender:</RadioLabel>
						<RadioInput
							value={gender}
							options={this.genderOptions}
							onChange={(gender) => this.setState({gender})}
							type="inline"
						/>
					</div>
				</Form>
			</div>
		);
	}
}
