import React, {Component} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./index.css";

const CommonButtonStyles = styled.button`
	font-family: inherit;
	font-size: 20px;
	color: inherit;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-image: ${(props) => (props.$icon ? `url(${props.$icon})` : null)};
	border: none;
	user-select: none;
`;

const DefaultButton = styled(CommonButtonStyles)`
	background-color: #0b39a2;
	display: flex;
	height: 60px;
	padding: 0 30px;
	border-radius: 100px;
	min-width: 175px;
	color: white;

	:hover {
		background-color: var(--midnight-blue);
	}
`;

const LargeButton = styled(CommonButtonStyles)`
	border: 2px solid var(--sky-blue);
	width: 296px;
	height: 166px;
	border-radius: 6px;
	background-color: white;

	&.active {
		background-color: var(--midnight-blue);
		font-weight: bold;
		color: white;
	}

	:hover {
		background-color: var(--light-grey);
		color: var(--midnight-blue);
	}

	:hover.active {
		background-color: var(--midnight-blue);
		opacity: 0.8;
		color: white;
	}

	:active {
		background-color: var(--midnight-blue);
		font-weight: bold;
		color: white;
	}
`;

const MinimalButton = styled(CommonButtonStyles)`
	background-color: transparent;
	border-radius: 30px;
	height: 60px;
	padding: 0 30px;

	:hover {
		background-color: #dffbff;
	}
`;

const typeComponentMap = {
	large: LargeButton,
	default: DefaultButton,
	minimal: MinimalButton,
};

export default class Button extends Component {
	render() {
		const {text, className, icon, onClick, type, active} = this.props;

		const classes = ["btn", className];

		if (icon) classes.push("icon");
		if (active) classes.push("active");

		const ButtonComponent = typeComponentMap[type];

		return (
			<ButtonComponent
				onClick={onClick}
				children={text}
				className={classes.join(" ")}
				$icon={`/images/icons/${icon}.svg`}
			/>
		);
	}
}

Button.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	className: PropTypes.string,
	type: PropTypes.string,
	active: PropTypes.bool,
	icon: PropTypes.string,
};

Button.defaultProps = {
	type: "default",
};
