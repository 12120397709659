import React, {Component} from "react";
import axios from "axios";
import * as QuestionViews from "./views";
import Button from "ui/Button";
import {serverDomain} from "util/constants/common";
import {
	NavigationButtonsTop,
	QuestionFlowWrapper,
	NavigationButtonsBottom,
} from "./QuestionFlowHelpers";

/*
	Properties passed from QuestionFlow to child components
		- setNextComponent: Function(nextComponentString). Child sets next component name to be rendered. Pass
		 null as parameter to disallow forward navigation.
		- setPersistence: Function({url, onResponse, data}). onResponse callback executed on HTTP request response.
		- advanceNavigation: Function(). Execute to take user to next page. Used by <Form> when user hits enter.
		- setMaxWidth: Function(maxWidthString). Pages may have different max widths.
*/

export class QuestionFlow extends Component {
	state = {
		currentComponent: "JourneyStart",
		progress: 0,
		maxWidth: "800",
		showTopNextButton: true,
		bottomNextButtonText: "Next",
	};

	componentHistory = [];
	nextComponent = null;
	persistence = {
		url: "",
		data: null,
		onResponse: null,
	};

	setNextComponent = (componentName) => {
		this.nextComponent = componentName;
	};

	setPersistence = ({url, data, onResponse}) => {
		this.persistence = {url, data, onResponse};
	};

	setMaxWidth = (maxWidth) => {
		this.setState({maxWidth});
	};

	setShowTopNextButton = (showTopNextButton) => {
		this.setState({showTopNextButton});
	};

	setBottomNextButtonText = (bottomNextButtonText) => {
		this.setState({bottomNextButtonText});
	};

	advanceNavigation = () => {
		const {currentComponent} = this.state;

		if (!this.nextComponent)
			return alert(
				"Oops! There's some error on the page. This is a temporary error code."
			);

		const {onResponse, url, data} = this.persistence;
		if (url) {
			axios
				.post(`${serverDomain}${url}`, data)
				.then((response) => {
					if (onResponse) onResponse(response.data);
				})
				.catch(console.error);
		}

		this.persistence = {url: "", data: null, onResponse: null};

		this.componentHistory.push(currentComponent);
		const {nextComponent} = this;
		this.nextComponent = null;
		this.setState({currentComponent: nextComponent});

		window.scrollTo(0, 0);
	};

	regressNavigation = () => {
		this.nextComponent = null;
		const previousComponent = this.componentHistory.pop();
		this.setState({currentComponent: previousComponent});
		window.scrollTo(0, 0);
	};

	render() {
		const {
			currentComponent,
			maxWidth,
			showTopNextButton,
			bottomNextButtonText,
		} = this.state;

		const CurrentComponentClass = QuestionViews[currentComponent];

		return (
			<>
				<NavigationButtonsTop>
					{this.componentHistory.length ? (
						<Button
							text="Back"
							onClick={this.regressNavigation}
							type="minimal"
							icon="left-chevron"
							className="prev"
						/>
					) : null}
					{showTopNextButton ? (
						<Button
							text="Next"
							onClick={this.advanceNavigation}
							className="next"
						/>
					) : null}
				</NavigationButtonsTop>

				<QuestionFlowWrapper $maxWidth={maxWidth}>
					<CurrentComponentClass
						setNextComponent={this.setNextComponent}
						setPersistence={this.setPersistence}
						advanceNavigation={this.advanceNavigation}
						setMaxWidth={this.setMaxWidth}
						setShowTopNextButton={this.setShowTopNextButton}
						setBottomNextButtonText={this.setBottomNextButtonText}
					/>
				</QuestionFlowWrapper>
				{bottomNextButtonText && false ? (
					<NavigationButtonsBottom>
						<Button
							text={bottomNextButtonText}
							onClick={this.advanceNavigation}
						/>
					</NavigationButtonsBottom>
				) : null}
			</>
		);
	}
}
