import styled from "styled-components";

export const FormStyled = styled.form`
	max-width: 450px;
	margin: auto;

	@media (min-width: 640px) {
		.form-row {
			display: flex;
			margin-bottom: 50px;
		}

		label {
			margin: 0 15px;
			width: 100%;
		}
	}

	@media (max-width: 639px) {
		label {
			margin-bottom: 25px;
			display: block;
		}
	}
`;
