/*
	The CustomSelect component is a styled equivalent to the HTML <select> element
	It looks great on all browsers, but doesn't utilize desktop browser's <select> functionality
	Prefer NativeSelect for mobile
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import DropDownList from "ui/DropDown/DropDownList";
import {cycle} from "util/common";
import "./index.css";

export class CustomSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dropdownVisible: false,
			highlightedRowIndex: -1,
		};

		this.dropDownRef = React.createRef();
	}

	componentDidMount() {
		window.addEventListener("click", this.handleWindowClick);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.handleWindowClick);
	}

	handleWindowClick = (e) => {
		const {dropdownVisible} = this.state;

		const clickedInput = e.target === this.dropDownRef.current;

		if (dropdownVisible && !clickedInput)
			this.setState({dropdownVisible: false});
		else if (clickedInput) this.setState({dropdownVisible: !dropdownVisible});
	};

	onSelectWrapper = (e) => {
		const {onChange} = this.props;

		onChange(e.value || e);
	};

	onKeyDownWrapper = (e) => {
		if (["Tab", "Escape"].includes(e.key)) {
			this.setState({dropdownVisible: false});
		} else if (e.key === "Enter") {
			return this.onEnterWrapper();
		} else if (["ArrowUp", "ArrowDown"].includes(e.key)) {
			e.preventDefault();
			return this.onNavigationArrowKey(e.key);
		}
	};

	onEnterWrapper = () => {
		const {dropdownVisible, highlightedRowIndex} = this.state;
		const {options} = this.props;

		if (dropdownVisible && highlightedRowIndex >= 0)
			this.onSelectWrapper(options[highlightedRowIndex]);

		this.setState({dropdownVisible: !dropdownVisible});
	};

	onNavigationArrowKey = (key) => {
		const {highlightedRowIndex} = this.state;
		const {options} = this.props;

		const increment = key === "ArrowUp" ? -1 : 1;
		this.setState({
			highlightedRowIndex: cycle(
				highlightedRowIndex,
				options.length,
				increment
			),
		});
	};

	render() {
		const {options, value} = this.props;
		const {dropdownVisible, highlightedRowIndex} = this.state;

		let label = value;
		if (options[0].label)
			label = options.find((op) => op.value.toString() === value.toString())
				.label;

		return (
			<div>
				<div
					children={label}
					className="select"
					ref={this.dropDownRef}
					tabIndex={0}
					onKeyDown={this.onKeyDownWrapper}
				/>
				{dropdownVisible ? (
					<DropDownList
						renderRow={(el) => el.label || el}
						elements={options}
						onSelect={this.onSelectWrapper}
						highlightedRowIndex={highlightedRowIndex}
					/>
				) : null}
			</div>
		);
	}
}

CustomSelect.propTypes = {
	value: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
};
