import React from "react";
import styled from "styled-components";
import QuestionnaireWrapper from "./QuestionnaireHelperComponents";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const Divider = styled.div`
	height: 1px;
	background-color: var(--sky-blue);
	margin: 22px auto;
	max-width: 640px;
`;

const description = (
	<>
		With some plans, your out-of-pocket expenses are minimal if you get sick.
		But these plans charge higher premiums that you pay each month.
		<Divider />
		Would you be willing to pay at least $150 a month for this sort of plan?
	</>
);

const config = {
	options: [
		{value: "YES", label: "Yes"},
		{value: "NO", label: "No"},
	],
	nextComponent: "QuestionnaireLocation",
	localStorageKey: LOCAL_STORAGE_CONSTS.PAGES.QUESTIONNAIRE_COSTS,
	questionKey: "PREMIUMS",
	icon: "pig",
	index: 3,
	title: "Costs",
	description,
	drawerText:
		"Some Medigap plans limit your out-of-pocket expenses because they charge much higher premiums. This question helps us to assess if you might prefer a plan like these. ",
};

export class QuestionnaireCosts extends QuestionnaireWrapper {
	constructor(props) {
		super(props, config);
	}
}
