import React, {Component} from "react";
import PropTypes from "prop-types";
import "./DropDownList.css";

const DropDownRow = ({row, isActive, renderRow, onSelect}) => {
	const classes = ["drop-down-row"];
	if (isActive) classes.push("active");

	return (
		<div
			className={classes.join(" ")}
			children={renderRow(row)}
			onClick={() => onSelect(row)}
		/>
	);
};

export default class DropDownList extends Component {
	render() {
		const {elements, highlightedRowIndex, renderRow, onSelect} = this.props;

		return (
			<div className="drop-down-results">
				{elements.length ? (
					elements.map((row, rowIndex) => (
						<DropDownRow
							isActive={highlightedRowIndex === rowIndex}
							row={row}
							key={row.key || row.value || row}
							renderRow={renderRow}
							onSelect={onSelect}
						/>
					))
				) : (
					<div className="drop-down-row" children="No results" />
				)}
			</div>
		);
	}
}

DropDownList.propTypes = {
	elements: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,
	renderRow: PropTypes.func.isRequired,
	highlightedRowIndex: PropTypes.number,
	className: PropTypes.string,
};

DropDownList.defaultProps = {
	className: "",
};
