import styled from "styled-components";

export const MedicareRetireHeader = styled.div`
	text-align: center;
	margin-bottom: 30px;

	h4 {
		margin-bottom: 5px;
	}
`;
