import React from 'react';
import {QuestionFlow} from 'pages/QuestionFlow';

const urlMappings = [{
	path: '/',
	name: 'QuestionFlow',
	component: <QuestionFlow />,
}, {
	path: '*',
	component: "not found :(",
}];

export {urlMappings}