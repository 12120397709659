import React from "react";
import styled from "styled-components";

const Box = styled.div`
	max-width: 480px;
	border-top: 18px solid;
	border-radius: 4px;
	background-color: white;
	border-color: var(--${(props) => `${props.$color}`});
	box-shadow: 0px 0px 4px 2px rgba(11, 33, 117, 0.15);

	h4 {
		margin: 0 0 36px 0;
		font-weight: bold;
	}

	@media (min-width: 640px) {
		padding: 15px 50px 70px 50px;
	}

	@media (max-width: 639px) {
		padding: 15px 25px;
	}
`;

export default (props) => {
	return (
		<Box $color={props.color}>
			<h4 children={props.title} />
			{props.children}
		</Box>
	);
};
