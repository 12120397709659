import React from "react";
import QuestionnaireWrapper from "./QuestionnaireHelperComponents";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const description = (
	<>
		Are you looking for additional coverage for dental, vision, and/or hearing?
		<br />
		(Check all that you're looking for.)
	</>
);

const config = {
	nextComponent: "MedicareAdvantageSummary",
	localStorageKey: LOCAL_STORAGE_CONSTS.PAGES.QUESTIONNAIRE_COVERAGE,
	questionKey: "EXTRA_COVERAGE",
	icon: "star",
	index: 6,
	title: "Extra Coverage",
	type: "checkbox",
	options: [
		{value: "dental", label: "Dental"},
		{value: "vision", label: "Vision"},
		{value: "hearing", label: "Hearing"},
	],
	description,
	drawerText:
		"Some Medicare Advantage plans include bundled coverage for dental, vision, and hearing care. This question helps us identify plans that have the benefits you'd like. ",
};

export class QuestionnaireExtraCoverage extends QuestionnaireWrapper {
	constructor(props) {
		super(props, config);
	}
}
