/*
QuestionnaireWrapper
	This component, extended by the various questionnaire classes, houses all the brains and styling of the
	 questionnaire pages. This somewhat unique solution was chosen due to the structure of QuestionFlow, the parent
	 component to the entire app.
*/

/**
 * config, passed via constructor has the following form.
 *
 * @param {Array} options The key/value pairs of radio buttons. Of form {value: 'SERVER_KEY', label: 'Readable Label'}.
 * @param {String} nextComponent The component to be rendered when user hits "next" button
 * @param {String} localStorageKey The localStorage key to save the selected button value
 * @param {String} questionKey The key that uniquely identifies this question on server
 * @param {String} icon The name of the SVG file located in `/public/images/icons`. E.g. `scales.svg`
 * @param {Number} index The question number to be displayed in the page header
 * @param {String} title The page and drawer header
 * @param {Node} description Helpful text giving context to the user
 * @see Location.js, Provider.js, etc.
 */

import React, {Component} from "react";
import styled from "styled-components";
import Button from "ui/Button";
import Checkbox from "ui/Checkbox";
import Icon from "ui/Icon";
import DrawerUnderline from "ui/Drawer/DrawerUnderline";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import endpoints from "util/constants/endpoints";

const QuestionnaireStyled = styled.div`
	text-align: center;
	margin-top: 75px;

	form {
		margin-top: 60px;
	}
`;

const IconBackdrop = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: var(--light-salmon);
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`;

const PageTitle = styled.h4`
	font-weight: bold;
`;

const PageDescription = styled.div`
	line-height: 32px;
	max-width: 800px;
	margin: 0 auto 30px auto;
`;

const ButtonRow = styled.div`
	margin-top: 50px;

	@media (min-width: 640px) {
		display: flex;
		justify-content: center;

		button {
			margin-right: 50px;
		}

		button:last-of-type {
			margin: 0;
		}
	}

	@media (max-width: 639px) {
		button {
			margin: 20px auto;
		}
	}
`;

export default class QuestionnaireWrapper extends Component {
	constructor(props, config) {
		super(props);

		props.setMaxWidth(1000);
		props.setNextComponent(config.nextComponent);
		props.setShowTopNextButton(true);

		const existingData = localStorage.getItem(config.localStorageKey);
		this.config = config;
		this.state = {};

		if (config.type === "checkbox") {
			this.state.selected = existingData ? existingData.split(",") : [];
		} else {
			this.state.selected = existingData ? existingData : "";
		}

		if (existingData) {
			this.setPersistence();
			props.setBottomNextButtonText("Next");
		} else {
			props.setBottomNextButtonText("Skip");
		}
	}

	setPersistence = () => {
		const {setPersistence} = this.props;
		const {selected} = this.state;

		const customerId = localStorage.getItem(LOCAL_STORAGE_CONSTS.CUSTOMER_ID);

		// TODO - Remove this once Janani opens endpoint to accept arrays
		const answer = Array.isArray(selected) ? selected[0] : selected;

		if (selected) {
			setPersistence({
				url: endpoints.questionnaireSave(),
				data: {
					customerId,
					question: this.config.questionKey,
					answer,
				},
			});
		}
	};

	componentDidUpdate(prevProps, prevState) {
		const {selected} = this.state;
		this.setPersistence();
		localStorage.setItem(this.config.localStorageKey, selected);
	}

	onSelectButton = (selected) => {
		const {advanceNavigation} = this.props;
		this.setState({selected}, advanceNavigation);
	};

	onToggleValue = (value) => {
		const {selected} = this.state;
		const {setBottomNextButtonText} = this.props;

		let newSelected;

		if (selected.includes(value))
			newSelected = selected.filter((s) => s !== value);
		else {
			newSelected = selected.concat(value);
		}

		this.setState({selected: newSelected});
		setBottomNextButtonText(newSelected.length ? "Next" : "Skip");
	};

	render() {
		const {selected} = this.state;

		return (
			<QuestionnaireStyled>
				<IconBackdrop>
					<Icon
						src={this.config.icon}
						height={62}
						color="var(--midnight-blue)"
					/>
				</IconBackdrop>
				<PageTitle
					children={`Question ${this.config.index}: ${this.config.title}`}
				/>
				<PageDescription children={this.config.description} />
				<DrawerUnderline
					drawerContent={() => this.config.drawerText}
					drawerTitle={this.config.title}
					text="Why we're asking this"
				/>
				{this.config.type === "checkbox" ? (
					<Checkbox
						selected={selected}
						options={this.config.options}
						onChange={this.onToggleValue}
						type="inline"
						large
					/>
				) : (
					<ButtonRow
						children={this.config.options.map((o) => (
							<Button
								text={o.label}
								active={selected === o.value}
								onClick={() => this.onSelectButton(o.value)}
								type="large"
								key={o.value}
							/>
						))}
					/>
				)}
			</QuestionnaireStyled>
		);
	}
}
