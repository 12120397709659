import React, {Component} from "react";
import styled from "styled-components";
import IconBox from "ui/IconBox";

const PageStructure = styled.div`
	text-align: center;
`;

const BoldedText = styled.span`
	font-weight: bold;
`;

const BoxRow = styled.div`
	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
		max-width: 640px;
		margin: 50px auto;
	}

	@media (max-width: 639px) {
		margin-top: 50px;
	}
`;

const BoxStyled = styled.div`
	border-top: 18px solid var(--sky-blue);
	border-radius: 4px;
	background-color: white;
	box-shadow: 0px 0px 3px rgba(0, 31, 103, 0.25);
	padding: 14px;

	@media (min-width: 640px) {
		width: 288px;
	}

	@media (max-width: 639px) {
		margin-bottom: 50px;
	}
`;

const Text = styled.div`
	position: relative;
	top: -30px;
`;

const Circle = styled.div`
	width: 68px;
	height: 68px;
	border-radius: 50%;
	background-color: var(--nice-blue);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 25px;
	position: relative;
	top: -55px;
	margin: 0 auto;
`;

const BoxHeader = styled.div`
	font-weight: bold;
	margin-bottom: 15px;
`;

const Box = (props) => {
	return (
		<BoxStyled>
			<Circle children={props.letter} />
			<Text>
				<BoxHeader>
					Part {props.letter}:
					<br />
					{props.title}
				</BoxHeader>
				<div children={props.description} />
			</Text>
		</BoxStyled>
	);
};

const PageHeader = styled.div`
	> * {
		margin-bottom: 30px;
	}
`;

export class BasicMedicare extends Component {
	constructor(props) {
		super(props);

		props.setMaxWidth(1000);
		props.setNextComponent("MedSupVsMedAdv");
	}
	content = [
		{
			letter: "A",
			title: "Hospital Insurance",
			description:
				"Covers inpatient care in a hospital or skilled nursing facility.",
		},
		{
			letter: "B",
			title: "Medical Insurance",
			description:
				"Covers medically necessary procedures and preventive visits.",
		},
	];

	render() {
		return (
			<PageStructure>
				<PageHeader>
					<h2>Basic Medicare</h2>
					<div>
						There are 2 parts of traditional Medicare: Part A and Part B
					</div>
				</PageHeader>
				<IconBox>
					Basic Medicare doesn’t cover all of your health costs –{" "}
					<BoldedText>
						you're responsible for paying 20% of your medical bills
					</BoldedText>
					. That’s why people look for additional Medicare coverage.
				</IconBox>
				<BoxRow>
					{this.content.map((b) => (
						<Box key={b.letter} {...b} />
					))}
				</BoxRow>
			</PageStructure>
		);
	}
}
