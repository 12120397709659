import React, {Component} from "react";
import styled from "styled-components";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const Header = styled.h2`
	text-align: center;
	margin-top: 250px;
`;

export class NiceToMeet extends Component {
	constructor(props) {
		super(props);

		this.state = {name: localStorage.getItem(LOCAL_STORAGE_CONSTS.FIRST_NAME)};
		props.setNextComponent("Medicare101");
		this.timeoutID = window.setTimeout(props.advanceNavigation, 5000);
	}

	componentWillUnmount() {
		window.clearTimeout(this.timeoutID);
	}

	render() {
		const {name} = this.state;

		return <Header children={`It's nice to meet you, ${name}!`} />;
	}
}
