import QuestionnaireWrapper from "./QuestionnaireHelperComponents";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const config = {
	options: [
		{value: "YES", label: "I'm fine getting a referral."},
		{value: "MAYBE", label: "Either way is fine."},
		{value: "NO", label: "I don't want referrals."},
	],
	nextComponent: "QuestionnaireCosts",
	localStorageKey: LOCAL_STORAGE_CONSTS.PAGES.QUESTIONNAIRE_REFERRALS,
	questionKey: "REFERRALS",
	icon: "provider",
	index: 2,
	title: "Referrals",
	description:
		"Some Medicare plans require you to obtain a referral from your primary care doctor before seeing a specialist. Do you mind needing a referral to see a specialist?",
	drawerText:
		"Many Medicare Advantage plans require you to receive a referral from a primary care physician before seeing a specialist. Medigap plans do not. This question helps us to assess which plan type might be a better fit for you. ",
};

export class QuestionnaireReferrals extends QuestionnaireWrapper {
	constructor(props) {
		super(props, config);
	}
}
