import React from "react";

export const x = ({color, height, width}) => (
	<svg
		width={width}
		height={height}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M1 1L18 19M18 1L1 19" stroke={color} strokeWidth="2" />
	</svg>
);
