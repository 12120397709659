import React from "react";
import styled from "styled-components";

const Waves = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 260px;
	background-image: url("/images/backgrounds/waves.svg");
	background-repeat: no-repeat;
	z-index: -1;
`;

export default () => {
	return null;
	// return <Waves />;
};
