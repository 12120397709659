import QuestionnaireWrapper from "./QuestionnaireHelperComponents";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const config = {
	options: [
		{value: "YES", label: "Yes"},
		{value: "NO", label: "No"},
	],
	nextComponent: "QuestionnairePrescriptions",
	localStorageKey: LOCAL_STORAGE_CONSTS.PAGES.QUESTIONNAIRE_LOCATION,
	questionKey: "LOCATION",
	icon: "house",
	index: 4,
	title: "Location",
	description:
		"Do you spend more than a few months of the year living in a different location?",
	drawerText:
		"If you spend significant portions of the year in multiple locations, you may want to be able to see a doctors in multiple states. Medigap plans help to cover care that you receive from any doctor that accepts traditional Medicare, regardless of location in the US. Medicare Advantage plans typically limit the areas where they will provide non-emergency coverage. ",
};

export class QuestionnaireLocation extends QuestionnaireWrapper {
	constructor(props) {
		super(props, config);
	}
}
