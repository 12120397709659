/**
 * Determine the next index of a cycled array. As if you taped two ends of an array together
 * @param {number} currentIndex - The current index
 * @param {number} count - The total number of elements in the array
 * @param {number} increment - The direction to step by (-1 or 1)
 * @return {number} The new index
 */

export const cycle = (currentIndex, count, increment) =>
	!currentIndex && increment === -1
		? count - 1
		: (currentIndex + increment) % count;
