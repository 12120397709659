import React, {Component} from "react";
import axios from "axios";
import {serverDomain} from "util/constants/common";
import endpoints from "util/constants/endpoints";
import {
	MedicareAdvantageRowHeaders,
	MedicareAdvantageSummaryColumn,
	MedicareAdvantageTable,
	MedicareAdvantageWrapper,
	SpacedText,
	Header,
	BottomBar,
	Loading,
} from "./MedicareAdvantageHelpers";
import tempServerResponse from "./TempServerResponse";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const flipCoin = () => Math.random() > 0.5;

const formatPlan = (plan) => {
	const planMapped = {
		coveredPrescriptionCount: plan.coveredPrescriptionCount,
		totalPrescriptionCount: plan.totalPrescriptionCount,
	};

	planMapped.referrals = flipCoin();

	planMapped.extraBenefits = {
		hearing: true,
		vision: flipCoin(),
		dental: flipCoin(),
	};

	const mappedCoveredDoctors = plan.coveredDoctors.map((p) => ({
		name: `${p.firstName} ${p.lastName}`,
		covered: true,
	}));

	const mappedUncoveredDoctors = plan.uncoveredDoctors.map((p) => ({
		name: `${p.firstName} ${p.lastName}`,
		covered: false,
	}));

	planMapped.doctors = mappedCoveredDoctors.concat(mappedUncoveredDoctors);

	planMapped.premiums = {
		min: Math.round((plan.ptDpremium + plan.premium) / 5) * 5,
	};

	planMapped.premiums.max = planMapped.premiums.min + 10;

	return planMapped;
};

export class MedicareAdvantageSummary extends Component {
	constructor(props) {
		super(props);

		props.setMaxWidth(1000);
		props.setShowTopNextButton(false);

		const name = localStorage.getItem(LOCAL_STORAGE_CONSTS.FIRST_NAME);

		this.state = {name, plans: []};
	}

	componentDidMount() {
		const customerId = localStorage.getItem(LOCAL_STORAGE_CONSTS.CUSTOMER_ID);
		const basicInfo = localStorage.getItem(
			LOCAL_STORAGE_CONSTS.PAGES.BASIC_INFO
		);

		let zipCode = "00000";
		if (basicInfo) {
			zipCode = JSON.parse(basicInfo).zipcode;
		}

		axios
			.post(`${serverDomain}${endpoints.recommendations()}`, {
				customerId,
				zipCode,
			})
			.then((response) => {
				let mapdPlans = response.data.mapdPlans.map(formatPlan);

				const localDoctors = JSON.parse(
					localStorage.getItem(LOCAL_STORAGE_CONSTS.PAGES.PROVIDER_SEARCH)
				);

				const localFormularies = JSON.parse(
					localStorage.getItem(LOCAL_STORAGE_CONSTS.PAGES.FORMULARY_SEARCH)
				);

				for (let i = mapdPlans.length; i < 3; i++) {
					const newPlan = formatPlan(tempServerResponse[i]);

					newPlan.doctors = localDoctors.map((d) => ({
						name: `${d.firstName} ${d.lastName}`,
						covered: flipCoin(),
					}));

					newPlan.coveredPrescriptionCount = localFormularies.length;
					newPlan.totalPrescriptionCount = localFormularies.length;

					mapdPlans.push(newPlan);
				}

				this.setState({plans: mapdPlans});
			})
			.catch(console.error);
	}

	render() {
		const {name, plans} = this.state;

		if (!plans.length) return <Loading />;

		return (
			<MedicareAdvantageWrapper>
				<Header>
					{`${name} – thanks for sharing your priorities. We’ve`}
					<br />
					short-listed {plans.length} <span>Medicare Advantage</span> plans for
					you.
				</Header>
				<MedicareAdvantageTable>
					<MedicareAdvantageRowHeaders />
					{plans.map((r, i) => (
						<MedicareAdvantageSummaryColumn key={i} plan={r} index={i} />
					))}
				</MedicareAdvantageTable>
				<SpacedText>
					<div>
						Many other Medicare advisors would ask you to apply to a plan now.
					</div>
					<b>We’re different.</b>
					<div>We believe in speaking with each of our clients.</div>
					<div>
						We want to further understand your needs and answer any questions
						you have.
					</div>
					<div>
						We often learn new information that helps confirm the right option
						for you.
					</div>
				</SpacedText>
				<BottomBar>
					<div>Save time – chat with us now!</div>
					<div>
						Call us at <a href="tel:833-986-0652">(833) 986-0652</a>
					</div>
				</BottomBar>
			</MedicareAdvantageWrapper>
		);
	}
}
