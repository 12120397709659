import React, {Component} from "react";
import styled from "styled-components";
import {Placeholder} from "ui/Placeholder";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import DropDown from "ui/DropDown";
import Provider from "./Provider";
import ProviderDropDownRow from "./ProviderDropDownRow";
import endpoints from "util/constants/endpoints";
import {serverDomain} from "util/constants/common";

const SearchView = styled.div`
	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
	}
`;

const StyledSearchDropDown = styled(DropDown)`
	@media (min-width: 640px) {
		width: 450px;
	}

	@media (max-width: 639px) {
		margin-bottom: 25px;
	}
`;

const formatProvider = (provider) => {
	provider.key = provider.npi;

	// Fix underscore naming convention
	provider.firstName = provider.first_name;
	provider.middleName = provider.middle_name;
	provider.lastName = provider.last_name;
	delete provider.first_name;
	delete provider.middle_name;
	delete provider.last_name;

	// Rename field
	provider.specialties = provider.specialtyList;
	delete provider.specialtyList;

	return provider;
};

export class ProviderSearch extends Component {
	constructor(props) {
		super(props);

		this.zip = "00000";
		const basicInfo = localStorage.getItem(
			LOCAL_STORAGE_CONSTS.PAGES.BASIC_INFO
		);
		if (basicInfo) this.zip = JSON.parse(basicInfo).zipcode;

		props.setMaxWidth(1000);
		props.setBottomNextButtonText("Next");
		// props.setShowTopNextButton(false);

		let existingData = localStorage.getItem(
			LOCAL_STORAGE_CONSTS.PAGES.PROVIDER_SEARCH
		);
		this.state = {
			selectedProviders: JSON.parse(existingData) || [],
		};

		props.setNextComponent("FormularySearch");
	}

	componentDidUpdate() {
		const {setPersistence} = this.props;
		const {selectedProviders} = this.state;

		const doctors = selectedProviders.map((provider) => {
			const newProvider = {...provider};

			const selectedLocation = newProvider.locations.find((l) => l.selected);

			newProvider.locationUuid = selectedLocation.uuid;
			newProvider.address = selectedLocation.address;
			newProvider.address_details = selectedLocation.address_details;
			newProvider.latitude = selectedLocation.latitude;
			newProvider.longitude = selectedLocation.longitude;

			delete newProvider.locations;

			return newProvider;
		});

		const customerId = localStorage.getItem(LOCAL_STORAGE_CONSTS.CUSTOMER_ID);

		setPersistence({
			url: endpoints.providerSave(),
			data: {
				customerId,
				doctors,
			},
		});

		localStorage.setItem(
			LOCAL_STORAGE_CONSTS.PAGES.PROVIDER_SEARCH,
			JSON.stringify(selectedProviders)
		);
	}

	addProvider = (provider) => {
		const {selectedProviders} = this.state;

		// TODO - temporary code. Sort by most confident location
		provider.locations.sort((a, b) => b.confidence - a.confidence);
		provider.locations[0].selected = true;
		selectedProviders.unshift(provider);

		this.setState({selectedProviders});
	};

	removeProvider = (providerNpi) => {
		const {selectedProviders} = this.state;

		const newProviders = [...selectedProviders].filter(
			(p) => providerNpi !== p.npi
		);

		this.setState({selectedProviders: newProviders});
	};

	mapProviderDropDownRow = (provider) => {
		return <ProviderDropDownRow provider={provider} key={provider.npi} />;
	};

	mapProvider = (provider) => {
		return (
			<Provider
				provider={provider}
				key={provider.npi}
				removeProvider={this.removeProvider}
			/>
		);
	};

	render() {
		const {selectedProviders} = this.state;

		return (
			<div>
				<h2 className="page-header">Let's find your doctors.</h2>
				<SearchView>
					<StyledSearchDropDown
						url={`${serverDomain}${endpoints.providerSearch({zip: this.zip})}`}
						onSelect={this.addProvider}
						placeholder="Type a name here"
						formatData={formatProvider}
						renderRow={this.mapProviderDropDownRow}
						autoFocus={true}
					/>
					{selectedProviders.length ? (
						<div children={selectedProviders.map(this.mapProvider)} />
					) : (
						<Placeholder
							icon="provider"
							text="Adding your doctors will help us find your plans where they are in-network."
							height={80}
						/>
					)}
				</SearchView>
			</div>
		);
	}
}
