import QuestionnaireWrapper from "./QuestionnaireHelperComponents";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const config = {
	options: [
		{value: "YES", label: "Yes"},
		{value: "MAYBE", label: "Maybe"},
		{value: "NO", label: "No"},
	],
	nextComponent: "QuestionnaireReferrals",
	localStorageKey: LOCAL_STORAGE_CONSTS.PAGES.QUESTIONNAIRE_PROVIDERS,
	questionKey: "PROVIDERS",
	icon: "provider",
	index: 1,
	title: "Providers",
	description:
		"Are you open to changing your current doctor(s) if it lowered your monthly premiums?",
	drawerText:
		"Some Medicare plans have only certain doctors in-network and will only cover care received in-network. Sometimes switching doctors can lower your insurance premiums, in situations where your doctor belongs only to networks associated with more expensive plans.",
};

export class QuestionnaireProviders extends QuestionnaireWrapper {
	constructor(props) {
		super(props, config);
	}
}
