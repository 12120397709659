import React, {Component} from "react";
import styled from "styled-components";
import Card from "ui/Card";

const MedSupVsMedAdvStyled = styled.div`
	text-align: center;
`;

const Description = styled.span`
	padding-bottom: 5px;
	border-bottom: 3px solid var(--dark-mint);
`;

const BoxRow = styled.div`
	@media (min-width: 640px) {
		display: flex;
		justify-content: space-between;
		margin-top: 50px;

		> div {
			flex: 1;
		}
	}

	@media (max-width: 639px) {
		> div {
			margin-top: 50px;
		}
	}
`;

const DotRow = styled.div`
	text-align: left;
	display: flex;
`;

const Dot = styled.div`
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background-color: var(--${(props) => `${props.$color}`});
	margin: 8px 15px 0 0;
`;

export class MedSupVsMedAdv extends Component {
	constructor(props) {
		super(props);
		props.setNextComponent("ProviderSearch");
		props.setMaxWidth(1000);
	}

	render() {
		const medAdvTitle = (
			<>
				Medicare Advantage <br />
				(aka Part C)
			</>
		);

		return (
			<MedSupVsMedAdvStyled>
				<h2>A Tale of Two Plans</h2>
				<Description children="There are two types of Medicare plans:" />
				<BoxRow>
					<Card title="Supplemental Medicare (Medigap)" color="sky-blue">
						<DotRow>
							<div>
								<Dot $color="sky-blue" />
							</div>
							<div>
								Add-on plans that help cover the 20% gap of costs that are not
								paid for by basic Medicare – that's where "Medigap" comes from.
							</div>
						</DotRow>
					</Card>
					<Card title={medAdvTitle} color="light-salmon">
						<DotRow>
							<div>
								<Dot $color="light-salmon" />
							</div>
							<div>
								These plans are substitutes for basic Medicare, and are covered
								through a private insurance company.
							</div>
						</DotRow>
					</Card>
				</BoxRow>
			</MedSupVsMedAdvStyled>
		);
	}
}
