import QuestionnaireWrapper from "./QuestionnaireHelperComponents";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const config = {
	options: [
		{value: "YES", label: "Yes"},
		{value: "NO", label: "No"},
	],
	nextComponent: "QuestionnaireExtraCoverage",
	localStorageKey: LOCAL_STORAGE_CONSTS.PAGES.QUESTIONNAIRE_PRESCRIPTIONS,
	questionKey: "PRESCRIPTIONS",
	icon: "formulary",
	index: 5,
	title: "Prescriptions",
	description: "Are you looking for prescription drug coverage?",
	drawerText:
		"Many Medicare Advantage plans include prescription drug coverage. However, Medigap plans do not cover prescription drugs. Most people who sign up for Medigap coverage consider purchasing standalone drug plan. Your answer will help us to assess the right plan type for you. ",
};

export class QuestionnairePrescriptions extends QuestionnaireWrapper {
	constructor(props) {
		super(props, config);
	}
}
