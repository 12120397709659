import React, {Component} from "react";
import PropTypes from "prop-types";
import {Checkbox, CheckboxWrapper} from "./CheckboxHelpers";

export default class CheckboxGroup extends Component {
	render() {
		const {selected, options, onChange, type, large} = this.props;

		return (
			<CheckboxWrapper type={type}>
				{options.map((o) => (
					<Checkbox
						key={o.value}
						checked={selected.includes(o.value)}
						onChange={() => onChange(o.value)}
						label={o.label}
						type={type}
						large={large}
					/>
				))}
			</CheckboxWrapper>
		);
	}
}

CheckboxGroup.propTypes = {
	selected: PropTypes.arrayOf(PropTypes.string).isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	type: PropTypes.oneOf(["column", "inline"]),
	large: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
	type: "inline",
	large: false,
};
