import React from "react";
import styled from "styled-components";
import Icon from "ui/Icon";

const IconBoxStyled = styled.div`
	background-color: white;
	display: flex;
	box-shadow: 0px 0px 3px rgba(0, 31, 103, 0.25);
	border-radius: 4px;
	line-height: 33px;
	text-align: left;

	@media (min-width: 640px) {
		padding: 29px 32px;
	}
	@media (max-width: 639px) {
		padding: 30px 18px;
	}
`;

const IconContainer = styled.div`
	@media (min-width: 640px) {
		margin-right: 58px;
	}
	@media (max-width: 639px) {
		margin-right: 20px;
	}
`;

export default (props) => {
	return (
		<IconBoxStyled>
			<IconContainer>
				<Icon src="magGlass" height={46} color="var(--peach)" />
			</IconContainer>
			<div children={props.children} />
		</IconBoxStyled>
	);
};
