import React from "react";

export const star = ({color, width, height}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 46 46"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.0138 24.516C27.5427 24.9453 27.3071 25.6131 27.4489 26.2332L28.8146 32.1479L23.7269 28.9521C23.4447 28.7613 23.1616 28.7136 22.8318 28.7136C22.502 28.7136 22.2194 28.809 21.9372 28.9521L16.8019 32.1003L18.1685 26.1855C18.3093 25.5654 18.1209 24.8976 17.6027 24.4683L13.0331 20.5093L19.016 20.0323C19.6755 19.9846 20.1937 19.5553 20.4763 18.9829L22.7847 13.3543L25.1401 18.9829C25.3757 19.603 25.9415 19.9846 26.6005 20.0323L32.5833 20.5093L28.0138 24.516ZM29.3334 25.8993L34.138 21.7018C34.6567 21.2248 34.8447 20.5093 34.6567 19.8415C34.4211 19.1737 33.8554 18.6967 33.1487 18.649L26.8837 18.1243L24.4336 12.2095C24.1514 11.5418 23.5385 11.1602 22.8319 11.1602C22.1252 11.1602 21.5128 11.5895 21.2306 12.2095L18.7805 18.1243L12.5155 18.649C11.8089 18.6967 11.2431 19.1737 11.0075 19.8415C10.772 20.5093 11.0075 21.2248 11.5257 21.7018L16.3308 25.8516L14.8709 32.0526C14.7291 32.7204 14.9647 33.4358 15.53 33.8651C16.0953 34.2944 16.849 34.2944 17.4143 33.9605L22.7848 30.6216L28.1557 33.9605C28.4378 34.1513 28.7205 34.199 29.0503 34.199C29.4271 34.199 29.7569 34.1036 30.04 33.8651C30.6048 33.4358 30.8404 32.7204 30.6991 32.0526L29.3334 25.8993Z"
			fill={color}
		/>
		<mask
			id="mask0"
			masktype="alpha"
			maskUnits="userSpaceOnUse"
			x="21"
			y="0"
			width="3"
			height="7"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.5225 0.919922H23.5819V6.99983H21.5225V0.919922Z"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask0)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.5522 6.99992C23.1185 6.99992 23.5819 6.58849 23.5819 6.08564V1.83421C23.5819 1.33135 23.1185 0.919922 22.5522 0.919922C21.9858 0.919922 21.5225 1.33135 21.5225 1.83421V6.08564C21.5225 6.58849 21.9858 6.99992 22.5522 6.99992Z"
				fill={color}
			/>
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.89251 11.5071C10.0886 11.7022 10.3338 11.7997 10.579 11.7997C10.8241 11.7997 11.0693 11.7022 11.2654 11.5071C11.6577 11.117 11.6577 10.4831 11.2654 10.1418L8.07827 6.97226C7.686 6.58216 7.04857 6.58216 6.70533 6.97226C6.31307 7.36235 6.31307 7.99626 6.70533 8.33759L9.89251 11.5071Z"
			fill={color}
		/>
		<mask
			id="mask1"
			masktype="alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="21"
			width="7"
			height="3"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.619141 21.6055H6.73291V23.6535H0.619141V21.6055Z"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask1)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.81362 21.6055H1.53852C1.03286 21.6055 0.619141 22.0663 0.619141 22.6295C0.619141 23.1927 1.03286 23.6535 1.53852 23.6535H5.81362C6.31928 23.6535 6.733 23.1927 6.733 22.6295C6.733 22.0663 6.31928 21.6055 5.81362 21.6055Z"
				fill={color}
			/>
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.89251 34.4918L6.70533 37.6613C6.31307 38.0519 6.31307 38.6858 6.70533 39.0271C6.90147 39.2217 7.14664 39.3192 7.3918 39.3192C7.63697 39.3192 7.88214 39.2217 8.07827 39.0271L11.2654 35.8576C11.6577 35.467 11.6577 34.8331 11.2654 34.4918C10.8732 34.1017 10.2357 34.1017 9.89251 34.4918Z"
			fill={color}
		/>
		<mask
			id="mask2"
			masktype="alpha"
			maskUnits="userSpaceOnUse"
			x="21"
			y="39"
			width="3"
			height="7"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.4189 39H23.4784V45.0799H21.4189V39Z"
				fill="white"
			/>
		</mask>
		<g mask="url(#mask2)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.4487 39C21.8823 39 21.4189 39.411 21.4189 39.9143V44.1657C21.4189 44.6681 21.8823 45.08 22.4487 45.08C23.015 45.08 23.4784 44.6681 23.4784 44.1657V39.9143C23.4784 39.411 23.015 39 22.4487 39Z"
				fill={color}
			/>
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.7521 34.4918C35.3598 34.1017 34.7224 34.1017 34.3792 34.4918C33.9869 34.8819 33.9869 35.5163 34.3792 35.8571L37.5663 39.0266C37.7625 39.2217 38.0076 39.3192 38.2528 39.3192C38.498 39.3192 38.7431 39.2217 38.9393 39.0266C39.3315 38.6366 39.3315 38.0026 38.9393 37.6613L35.7521 34.4918Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M44.7013 22.0391H40.2012C39.6689 22.0391 39.2334 22.4711 39.2334 22.9991C39.2334 23.5271 39.6689 23.9591 40.2012 23.9591H44.7013C45.2335 23.9591 45.669 23.5271 45.669 22.9991C45.669 22.4711 45.2335 22.0391 44.7013 22.0391Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M35.0657 11.7997C35.3108 11.7997 35.556 11.7022 35.7516 11.5071L38.9389 8.33759C39.3317 7.9475 39.3317 7.31359 38.9389 6.97226C38.5471 6.58216 37.9097 6.58216 37.5664 6.97226L34.3792 10.093C33.9869 10.4831 33.9869 11.117 34.3792 11.4584C34.5753 11.7022 34.8205 11.7997 35.0657 11.7997Z"
			fill={color}
		/>
	</svg>
);
