import React, {Component} from "react";
import styled from "styled-components";
import BoxTriangle from "ui/BoxTriangle";

const Medicare101Styled = styled.div`
	text-align: center;
`;

const TextBottom = styled.div`
	div {
		margin-bottom: 15px;
	}
`;

export class Medicare101 extends Component {
	constructor(props) {
		super(props);
		props.setNextComponent("BasicMedicare");
		props.setBottomNextButtonText(null);
		props.setShowTopNextButton(true);
		props.setMaxWidth(800);
	}

	render() {
		return (
			<Medicare101Styled>
				<h2>Medicare 101</h2>
				<BoxTriangle children="What is Medicare?" />
				<TextBottom>
					<div>
						Medicare is federal health insurance available to people who are 65+
						(that’s you!)
					</div>
					<div>
						Medicare is something that you have earned through years of paying
						income taxes.
					</div>
					<div>
						So congratulations! Medicare is something you’ve worked for – and
						you’re entitled to get coverage that best fits your needs.
					</div>
				</TextBottom>
			</Medicare101Styled>
		);
	}
}
