import React, {Component} from "react";
import styled from "styled-components";
import Icon from "ui/Icon";
import {PlanAspectsDivider, PlanAspectsWrapper} from "./PlanAspectsHelpers";

const Header = styled.h2`
	text-align: center;
`;

const IconRow = styled.div`
	display: flex;
	justify-content: center;
	text-align: center;

	> * {
		width: 25%;
	}
`;

const IconWrapper = (props) => {
	return (
		<div>
			<IconBackdrop active={props.active} onClick={props.onClick}>
				<Icon
					src={props.src}
					height={69}
					color={props.active ? "white" : "var(--midnight-blue)"}
				/>
			</IconBackdrop>
			{props.title}
		</div>
	);
};

const IconBackdrop = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto 22px auto;
	background-color: var(
		--${({active}) => (active ? "midnight-blue" : "light-salmon")}
	);
	overflow: hidden;
`;

const config = [
	{src: "pig", content: "more info about costs!", title: "Costs"},
	{
		src: "scales",
		content: "more info about Risk Preference!",
		title: "Risk Preference",
	},
	{
		src: "provider",
		content: "more info about Doctors & Prescriptions!",
		title: "Doctors & Prescriptions",
	},
	{
		src: "star",
		content: "more info about Extra Coverage!",
		title: "Extra Coverage",
	},
];

export class PlanAspects extends Component {
	constructor(props) {
		super(props);
		props.setNextComponent("BasicInfo");
		props.setMaxWidth(1000);

		this.state = {selectedIconIndex: 0};
	}

	render() {
		const {selectedIconIndex} = this.state;

		return (
			<PlanAspectsWrapper>
				<Header children="Choosing the right plan for you" />
				<IconRow>
					{config.map((c, index) => (
						<IconWrapper
							{...c}
							key={c.src}
							onClick={() => this.setState({selectedIconIndex: index})}
							active={index === selectedIconIndex}
						/>
					))}
				</IconRow>
				<PlanAspectsDivider
					index={selectedIconIndex}
					content={config[selectedIconIndex].content}
				/>
			</PlanAspectsWrapper>
		);
	}
}
