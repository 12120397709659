export default [
	{
		bidId: "H5216_092_0",
		bidIdWithoutSegment: "H5216-092",
		formularyId: "20457",
		planName: "HumanaChoice H5216-092 (PPO)",
		ptDpremium: 0,
		ptDdeductible: 350,
		totalPrescriptionCosts: 48,
		totalPrescriptionCount: 1,
		costType: "Retail",
		prescriptions: [
			{
				rxcui: 200033,
				tier: 1,
				monthlyRetailPricing: 4,
				monthlyMailOrderPricing: 4,
				deductibleApplies: false,
			},
		],
		premium: 38,
		expOOP: 504,
		totalDoctors: 1,
		coveredDoctors: [
			{
				id: 234,
				npi: "1023190543",
				firstName: "Jonathan",
				lastName: "Goodman",
				specialties: "Internal Medicine",
				locationUuid: "59ca82a5-af06-44ae-a8c8-49b080b1f9db",
				address: "909 Fulton St SE, Minneapolis, MN 55455, US",
				latitude: 44.970837,
				longitude: -93.22498,
			},
		],
		uncoveredDoctors: [],
		doctorsCoveredCount: 1,
		coveredPrescriptionCount: 1,
	},
	{
		bidId: "H5216_167_0",
		bidIdWithoutSegment: "H5216-167",
		formularyId: "20457",
		planName: "HumanaChoice H5216-167 (PPO)",
		ptDpremium: 20,
		ptDdeductible: 350,
		totalPrescriptionCosts: 240,
		totalPrescriptionCount: 1,
		costType: "Retail",
		prescriptions: [
			{
				rxcui: 200033,
				tier: 1,
				monthlyRetailPricing: 0,
				monthlyMailOrderPricing: 0,
				deductibleApplies: false,
			},
		],
		premium: 68,
		expOOP: 1056,
		totalDoctors: 1,
		coveredDoctors: [
			{
				id: 234,
				npi: "1023190543",
				firstName: "Jonathan",
				lastName: "Goodman",
				specialties: "Internal Medicine",
				locationUuid: "59ca82a5-af06-44ae-a8c8-49b080b1f9db",
				address: "909 Fulton St SE, Minneapolis, MN 55455, US",
				latitude: 44.970837,
				longitude: -93.22498,
			},
		],
		uncoveredDoctors: [],
		doctorsCoveredCount: 1,
		coveredPrescriptionCount: 1,
	},
	{
		bidId: "H5216_063_0",
		bidIdWithoutSegment: "H5216-063",
		formularyId: "20457",
		planName: "HumanaChoice H5216-063 (PPO)",
		ptDpremium: 9,
		ptDdeductible: 250,
		totalPrescriptionCosts: 108,
		totalPrescriptionCount: 1,
		costType: "Retail",
		prescriptions: [
			{
				rxcui: 200033,
				tier: 1,
				monthlyRetailPricing: 0,
				monthlyMailOrderPricing: 0,
				deductibleApplies: false,
			},
		],
		premium: 97.4,
		expOOP: 1276.8,
		totalDoctors: 1,
		coveredDoctors: [
			{
				id: 234,
				npi: "1023190543",
				firstName: "Jonathan",
				lastName: "Goodman",
				specialties: "Internal Medicine",
				locationUuid: "59ca82a5-af06-44ae-a8c8-49b080b1f9db",
				address: "909 Fulton St SE, Minneapolis, MN 55455, US",
				latitude: 44.970837,
				longitude: -93.22498,
			},
		],
		uncoveredDoctors: [],
		doctorsCoveredCount: 1,
		coveredPrescriptionCount: 1,
	},
];
