import React, {Component} from "react";
import styled from "styled-components";
import TextInput from "ui/TextInput";
import Waves from "ui/Waves";
import {capitalize} from "util/format";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";

const Container = styled.div`
	@media (min-width: 640px) {
		margin-top: 100px;
	}

	h4,
	input {
		text-align: center;
	}

	input::placeholder {
		color: #b9d8f8;
	}
`;

const NameInput = styled(TextInput)`
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0;
	background-color: transparent;
	font-size: 39px;
`;

export class FirstName extends Component {
	nextComponent = "NextExistingPlan";

	constructor(props) {
		super(props);

		props.setMaxWidth(450);

		const name = localStorage.getItem(LOCAL_STORAGE_CONSTS.FIRST_NAME);
		if (name) {
			this.state = {name};
			props.setNextComponent(name.length >= 3 ? this.nextComponent : null);
		} else {
			this.state = {name: ""};
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {setNextComponent} = this.props;
		const {name} = this.state;

		setNextComponent(name.length >= 3 ? this.nextComponent : null);

		localStorage.setItem(LOCAL_STORAGE_CONSTS.FIRST_NAME, name);
	}

	onKeyDownWrapper = (key) => {
		const {advanceNavigation} = this.props;
		const {name} = this.state;

		if (key === "Enter" && name.length >= 3) advanceNavigation();
	};

	render() {
		const {name} = this.state;

		return (
			<>
				<Container>
					<h4>What is your first name?</h4>
					<NameInput
						value={name}
						onChange={(name) => this.setState({name: capitalize(name)})}
						placeholder="Type Here"
						onKeyDown={this.onKeyDownWrapper}
					/>
				</Container>
				<Waves />
			</>
		);
	}
}
