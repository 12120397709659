import React, {PureComponent} from "react";
import styled from "styled-components";
import Touchable from "core/Touchable";

const HeaderFullWidth = styled.div`
	background-color: white;
	box-shadow: 0px 0px 3px rgba(0, 31, 103, 0.25);
`;

const HeaderWrapper = styled.div`
	margin-bottom: 20px;
	margin: auto;
	padding: 15px;
	max-width: 1000px;
`;

const Logo = styled.img`
	width: 100px;
`;

export default class Header extends PureComponent {
	render() {
		return (
			<HeaderFullWidth>
				<HeaderWrapper>
					<Touchable href="/">
						<Logo alt="site-logo" src="/images/icons/logo.svg" />
					</Touchable>
				</HeaderWrapper>
			</HeaderFullWidth>
		);
	}
}
