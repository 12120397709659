import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Icon from 'ui/Icon';
import './index.css'

export class Placeholder extends Component {
	render() {
		const {iconAlt, text, icon, height} = this.props;

		return (
			<div className="placeholder">
				<div className="placeholder-circle">
					<Icon src={icon} alt={iconAlt} className="placeholder-icon" height={height}/>
				</div>
				<div children={text} />
			</div>
		);
	}
}

Placeholder.propTypes = {
	icon: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	height: PropTypes.number,
	className: PropTypes.string,
	iconAlt: PropTypes.string,
}

Placeholder.defaultProps = {
	iconAlt: '',
}