import React, {Component} from "react";
import PropTypes from "prop-types";
import {
	RadioFillDot,
	RadioInputWrapper,
	RadioGroupWrapper,
} from "./RadioInputHelpers";

export default class RadioInput extends Component {
	renderRadioElement = (option) => {
		const {value} = this.props;

		const isActive = option.value === value;

		return (
			<RadioInputWrapper
				key={option.value}
				active={isActive}
				tabIndex="0"
				onKeyDown={(e) => this.onKeyDownWrapper(e.key, option.value)}
			>
				<input
					type="radio"
					checked={isActive}
					onChange={this.onChangeWrapper}
					value={option.value}
				/>
				<RadioFillDot active={isActive} />
				{option.label}
			</RadioInputWrapper>
		);
	};

	onKeyDownWrapper = (key, value) => {
		if (key === "Enter") this.props.onChange(value);
	};

	onChangeWrapper = (e) => {
		this.props.onChange(e.target.value);
	};

	render() {
		const {options, type} = this.props;

		const radioElements = options.map(this.renderRadioElement);

		return <RadioGroupWrapper children={radioElements} type={type} />;
	}
}

RadioInput.propTypes = {
	value: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	type: PropTypes.oneOf(["column", "inline"]),
};

RadioInput.defaultProps = {
	type: "column",
};
