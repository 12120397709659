import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import "./index.css";

const InputWrapper = styled.input`
	border: 2px solid var(--sky-blue);
	border-radius: 4px;
	padding: 13px 20px;
	width: 100%;

	[type="number"] {
		text-align: left;
	}
`;

const TextInputLabel = styled.div`
	font-size: 16px;
	margin-bottom: 11px;
`;

export default class TextInput extends Component {
	onChangeWrapper = (e) => {
		const {onChange} = this.props;

		onChange(e.target.value);
	};

	onKeyDownWrapper = (e) => {
		const {onKeyDown} = this.props;

		if (onKeyDown) onKeyDown(e.key);
	};

	onBlurWrapper = (e) => {
		const {validator} = this.props;

		if (validator) {
			try {
				validator(e.target.value);
			} catch (e) {
				console.log(e);
			}
		}
	};

	render() {
		const {
			value,
			placeholder,
			className,
			type,
			title,
			minLength,
			childRef,
			icon,
			min,
			max,
			autoFocus,
		} = this.props;

		const style = {};
		const classes = [className];

		if (icon) {
			style.backgroundImage = `url(${icon})`;
			classes.push("text-input-icon");
		}

		return (
			<label>
				{title ? <TextInputLabel children={title} /> : null}
				<InputWrapper
					value={value}
					placeholder={placeholder}
					className={classes.join(" ")}
					type={type}
					minLength={minLength}
					ref={childRef}
					onKeyDown={this.onKeyDownWrapper}
					onChange={this.onChangeWrapper}
					onBlur={this.onBlurWrapper}
					style={style}
					min={min}
					max={max}
					autoFocus={autoFocus}
				/>
			</label>
		);
	}
}

TextInput.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string,
	minlength: PropTypes.string,
	childRef: PropTypes.object,
	icon: PropTypes.string,
	validator: PropTypes.func,
	autoFocus: PropTypes.bool,
	min: PropTypes.number,
	max: PropTypes.number,
};

TextInput.defaultProps = {
	placeholder: "",
	className: "",
	type: "text",
	autoFocus: false,
};
