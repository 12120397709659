import React, {Component} from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {urlMappings} from "util/Router/urlMappings";
import Header from "./core/Header";
import Drawer from "./ui/Drawer";
import "./App.css";

class App extends Component {
	render() {
		return (
			<Router>
				<Switch>{urlMappings.map(this.renderRoute)}</Switch>
			</Router>
		);
	}

	renderRoute = (route) => {
		return (
			<Route path={route.path} key={route.path} exact>
				<Header />
				<Drawer />
				<div className="wrapper" children={route.component} />
			</Route>
		);
	};
}

export default App;
