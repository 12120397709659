import styled from "styled-components";
import RadioInput from "ui/RadioInput";

export const NewExistingPlanWrapper = styled.div`
	text-align: center;
`;

export const RadioInputCentered = styled(RadioInput)`
	margin: auto;
`;
