import React from "react";

export const scales = ({color}) => (
	<svg width="59" height="62" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="59" height="62">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M.003.217h58.875v61.781H.003V.217z"
				fill="#fff"
			/>
		</mask>
		<g mask="url(#a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.44 2.756c-1.19 0-2.155.966-2.155 2.157S28.25 7.07 29.44 7.07c1.19 0 2.154-.966 2.154-2.157a2.15 2.15 0 00-2.154-2.157zM4.462 26.25h9.168c-1.536-2.851-3.062-5.712-4.589-8.563-1.526 2.851-3.053 5.712-4.58 8.563zm11.041 2.86H2.58c.365 3.123 3.521 4.952 6.462 4.952 2.94 0 6.087-1.83 6.462-4.952zm29.744-2.86h9.177c-1.526-2.851-3.053-5.712-4.58-8.563-1.526 2.851-3.052 5.712-4.597 8.563zm11.05 2.86H43.374c.375 3.123 3.522 4.952 6.472 4.952 2.931 0 6.087-1.83 6.452-4.952zM22.687 51.513c.702 0 1.274.563 1.274 1.266 0 .704-.572 1.266-1.274 1.266h-5.45a2.717 2.717 0 00-2.66 2.701v2.71H44.31v-2.71c0-1.463-1.199-2.672-2.66-2.7H31.284a1.268 1.268 0 01-1.273-1.267c0-.703.57-1.266 1.273-1.266h6.734c-1.845-2.738-5.385-4.023-8.579-4.023-3.193 0-6.733 1.285-8.578 4.023h1.826zM29.439.215a4.704 4.704 0 014.692 4.698 4.693 4.693 0 01-3.325 4.492v2.701h19.442c.759 0 1.368.61 1.368 1.37 0 .45-.225.853-.562 1.106L57.3 26.25h1.274c.094.544.234 1.125.281 1.669.44 5.092-4.233 8.684-9.01 8.684-4.785 0-9.458-3.592-9.018-8.684.056-.544.178-1.125.272-1.67h1.283l6.106-11.403H30.807v30.17c4.101.374 8.372 2.578 10.104 6.498h.703c2.875 0 5.235 2.364 5.235 5.233v5.252h-34.82v-5.252c0-2.87 2.36-5.233 5.245-5.233h.693c1.733-3.92 6.003-6.124 10.105-6.499v-30.17H10.39l6.106 11.405h1.283c.093.544.224 1.125.271 1.669.44 5.092-4.223 8.684-9.009 8.684-4.776 0-9.459-3.582-9.01-8.684.048-.544.179-1.125.272-1.67h1.274l6.256-11.666a1.374 1.374 0 01-.571-1.107c0-.76.618-1.369 1.367-1.369h19.442v-2.7a4.693 4.693 0 01-3.325-4.493A4.704 4.704 0 0129.44.215z"
				fill={color}
			/>
		</g>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M27.505 52.28c.675 0 1.215.55 1.215 1.224 0 .666-.54 1.215-1.215 1.215a1.223 1.223 0 01-1.224-1.215c0-.674.549-1.223 1.224-1.223z"
			fill={color}
		/>
	</svg>
);
