export default {
	CUSTOMER_ID: "CUSTOMER_ID",
	FIRST_NAME: "FIRST_NAME",
	PAGES: {
		BASIC_INFO: "BASIC_INFO",
		FORMULARY_SEARCH: "FORMULARY_SEARCH",
		PROVIDER_SEARCH: "PROVIDER_SEARCH",
		QUESTIONNAIRE_COSTS: "QUESTIONNAIRE_COSTS",
		QUESTIONNAIRE_PROVIDERS: "QUESTIONNAIRE_PROVIDERS",
		QUESTIONNAIRE_REFERRALS: "QUESTIONNAIRE_REFERRALS",
		QUESTIONNAIRE_LOCATION: "QUESTIONNAIRE_LOCATION",
		QUESTIONNAIRE_PRESCRIPTIONS: "QUESTIONNAIRE_PRESCRIPTIONS",
		QUESTIONNAIRE_EXTRA_COVERAGE: "QUESTIONNAIRE_RISK",
		QUESTIONNAIRE_COVERAGE: "QUESTIONNAIRE_COVERAGE",
		INTRO_FIRST_EXISTING: "INTRO_FIRST_EXISTING",
		INTRO_TOBACCO: "INTRO_TOBACCO",
		INTRO_MEDICARE_RETIRE: "INTRO_MEDICARE_RETIRE",
	},
};
