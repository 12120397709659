import React, {Component} from "react";
import styled from "styled-components";
import Icon from "ui/Icon";

const ProviderStyled = styled.div`
	border-top: 18px solid var(--sky-blue);
	border-radius: 4px;
	background-color: white;
	margin-bottom: 25px;
	box-shadow: 0px 0px 4px 2px rgba(11, 33, 117, 0.15);

	@media (min-width: 640px) {
		width: 480px;
		padding: 30px 35px 25px 64px;
	}

	@media (max-width: 639px) {
		padding: 30px 35px 25px 30px;
	}
`;

const ProviderTitle = styled.div`
	font-weight: bold;
	margin-bottom: 30px;
	line-height: 23px;
`;

const IconRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px 0 0 auto;
	width: 80px;
`;

const AddressRow = styled.div`
	display: flex;

	img {
		margin-right: 20px;
	}
`;

export default class Provider extends Component {
	render() {
		const {provider, removeProvider} = this.props;

		const {address_details} = provider.locations.find((l) => l.selected);

		return (
			<ProviderStyled>
				<ProviderTitle>
					<div children={`${provider.firstName} ${provider.lastName}`} />
					<div children={provider.specialties[0]} />
				</ProviderTitle>
				<AddressRow>
					<Icon src="pin" height={38} />
					<div>
						<div children={address_details.street} />
						<div
							children={`${address_details.city}, ${address_details.state} ${address_details.zip}`}
						/>
					</div>
				</AddressRow>
				<IconRow>
					<Icon src="trash" onClick={() => removeProvider(provider.npi)} />
				</IconRow>
			</ProviderStyled>
		);
	}
}
