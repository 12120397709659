import React, {Component} from "react";
import {Drawer as BPDrawer} from "@blueprintjs/core";
import {DrawerContentWrapper} from "./DrawerContentWrapper";

let instance;

export default class Drawer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			CurrentComponent: () => null,
			title: "",
			isOpen: false,
		};

		this.isDesktop = window.innerWidth >= 640;

		instance = this;
	}

	static show = ({title, content}) => {
		instance.setState({
			isOpen: true,
			CurrentComponent: content,
			title,
		});
	};

	static hide = () => {
		instance.setState({isOpen: false});
	};

	render() {
		const {CurrentComponent, isOpen, title} = this.state;

		return (
			<BPDrawer
				isOpen={isOpen}
				onClose={Drawer.hide}
				size={this.isDesktop ? "538px" : "90%"}
				isCloseButtonShown={false}
				title={title}
				hasBackdrop={false}
			>
				<DrawerContentWrapper children={<CurrentComponent />} />
			</BPDrawer>
		);
	}
}
