import React, {Component} from "react";
import {FormGroup} from "@blueprintjs/core";
import DropDown from "ui/DropDown";
import TextInput from "ui/TextInput";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import Icon from "ui/Icon";
import {Placeholder} from "ui/Placeholder";
import {NativeSelect} from "ui/Select/NativeSelect";
import {CustomSelect} from "ui/Select/CustomSelect";
import Button from "ui/Button";
import {capitalize} from "util/format";
import endpoints from "util/constants/endpoints";
import {serverDomain} from "util/constants/common";
import "./index.css";

// TODO: move this to a constants file
const frequencies = [
	{
		value: "Daily",
		label: "Day",
	},
	{
		value: "Weekly",
		label: "Week",
	},
	{
		value: "Monthly",
		label: "Month",
	},
	{
		value: "Other",
		label: "Other",
	},
];

const formatFormulary = (formulary) => {
	formulary.selectedFrequency = frequencies[0].value;
	formulary.selectedUnitsPerDose = "1";
	formulary.key = formulary.brandName + formulary.compounds;
	formulary.compounds = capitalize(formulary.compounds);
	formulary.brandName = capitalize(formulary.brandName);

	// Normalize formularyDosagesAndForms array and populate doseFormMapping
	const doseFormMapping = {};
	formulary.formularyDosagesAndForms = formulary.formularyDosagesAndForms.map(
		(dosageAndForm, index) => {
			dosageAndForm.doseForm = capitalize(dosageAndForm.doseForm);
			dosageAndForm.dosage = capitalize(dosageAndForm.dosage);

			if (index === 0) {
				formulary.selectedDoseForm = dosageAndForm.doseForm;
				formulary.selectedRxcuid = dosageAndForm.rxcuid;
			}

			const dosageRxcuid = {
				value: dosageAndForm.rxcuid,
				label: dosageAndForm.dosage,
			};

			if (doseFormMapping[dosageAndForm.doseForm])
				doseFormMapping[dosageAndForm.doseForm].push(dosageRxcuid);
			else doseFormMapping[dosageAndForm.doseForm] = [dosageRxcuid];

			return dosageAndForm;
		}
	);
	formulary.doseFormMapping = doseFormMapping;

	return formulary;
};

export class FormularySearch extends Component {
	constructor(props) {
		super(props);

		props.setMaxWidth(1000);

		this.SelectComponent =
			document.body.clientWidth >= 640 ? CustomSelect : NativeSelect;

		let existingData = localStorage.getItem(
			LOCAL_STORAGE_CONSTS.PAGES.FORMULARY_SEARCH
		);
		this.state = {
			selectedFormularies: JSON.parse(existingData) || [],
		};

		props.setNextComponent("QuestionnaireProviders");
	}

	componentDidUpdate() {
		const {setPersistence} = this.props;
		const {selectedFormularies} = this.state;

		const prescriptionInfos = selectedFormularies.map((formulary) => {
			return {
				frequencyType: formulary.selectedFrequency,
				rxcui: formulary.selectedRxcuid,
				count: formulary.selectedUnitsPerDose,
			};
		});

		const customerId = localStorage.getItem(LOCAL_STORAGE_CONSTS.CUSTOMER_ID);

		setPersistence({
			url: endpoints.formularySave(),
			data: {
				customerId,
				prescriptionInfos,
			},
		});

		localStorage.setItem(
			LOCAL_STORAGE_CONSTS.PAGES.FORMULARY_SEARCH,
			JSON.stringify(selectedFormularies)
		);
	}

	renderDropDownRow = (formulary) => {
		if (formulary.brandName === "Null")
			return <div children={formulary.compounds} />;
		else
			return (
				<div>
					{formulary.brandName}
					<br />
					{`Generic: ${formulary.compounds}`}
				</div>
			);
	};

	renderFormulary = (formulary) => {
		const formularyTitle =
			formulary.brandName === "Null"
				? capitalize(formulary.compounds)
				: capitalize(`${formulary.brandName} (${formulary.compounds})`);

		return (
			<div key={formulary.key} className="selected-formulary">
				<div className="selected-formulary-top">
					<div children={formularyTitle} />
					<Icon
						onClick={() => this.removeFormulary(formulary)}
						src="trash"
						height={27}
					/>
				</div>
				{this.renderFormularyAdditionalInfo(formulary)}
			</div>
		);
	};

	renderFormularyAdditionalInfo = (formulary) => {
		const {SelectComponent} = this;
		if (!formulary.showAdditionalInfo)
			return (
				<Button
					text="Add dosage info"
					onClick={() =>
						this.editFormulary(formulary.key, "showAdditionalInfo", true)
					}
					icon="plus"
				/>
			);

		return (
			<div>
				<div className="selected-formulary-row">
					<FormGroup label="Form">
						<SelectComponent
							value={formulary.selectedDoseForm}
							options={Object.keys(formulary.doseFormMapping)}
							onChange={(val) =>
								this.editFormulary(formulary.key, "selectedDoseForm", val)
							}
							large={true}
						/>
					</FormGroup>
					<FormGroup label="Dosage">
						<SelectComponent
							value={formulary.selectedRxcuid.toString()}
							options={formulary.doseFormMapping[formulary.selectedDoseForm]}
							onChange={(val) =>
								this.editFormulary(formulary.key, "selectedRxcuid", val)
							}
							large={true}
						/>
					</FormGroup>
				</div>
				<div className="selected-formulary-row">
					<FormGroup
						label="How often do you take this medication?"
						contentClassName="selected-formulary-frequency"
					>
						<TextInput
							value={formulary.selectedUnitsPerDose}
							onChange={(val) =>
								this.editFormulary(formulary.key, "selectedUnitsPerDose", val)
							}
							type="number"
							min={1}
							max={1000}
						/>
						<div className="selected-formulary-frequency-spacer">per</div>
						<SelectComponent
							value={formulary.selectedFrequency}
							options={frequencies}
							onChange={(val) =>
								this.editFormulary(formulary.key, "selectedFrequency", val)
							}
							large={true}
						/>
					</FormGroup>
				</div>
			</div>
		);
	};

	addFormulary = (formulary) => {
		const {selectedFormularies} = this.state;

		selectedFormularies.unshift(formulary);

		this.setState({selectedFormularies});
	};

	removeFormulary = (formulary) => {
		const {selectedFormularies} = this.state;

		const newFormularies = [...selectedFormularies].filter(
			(f) => formulary.key !== f.key
		);
		this.setState({selectedFormularies: newFormularies});
	};

	editFormulary = (formularyKey, field, value) => {
		const {selectedFormularies} = this.state;

		selectedFormularies.forEach((f) => {
			if (f.key !== formularyKey) return;

			// Update Dosage if user selects a new Form
			if (field === "selectedDoseForm" && f.selectedDoseForm !== value)
				f.selectedRxcuid = f.doseFormMapping[value][0].value;

			f[field] = value;
		});

		this.setState({selectedFormularies});
	};

	render() {
		const {selectedFormularies} = this.state;

		return (
			<div>
				<h2 className="page-header">Your Prescriptions</h2>
				<div className="formularies-content">
					<DropDown
						url={`${serverDomain}${endpoints.formularySearch({})}`}
						onSelect={this.addFormulary}
						placeholder="Type a drug here"
						formatData={formatFormulary}
						renderRow={this.renderDropDownRow}
						className="formulary-dropdown"
						autoFocus={true}
					/>
					{selectedFormularies.length ? (
						<div
							children={selectedFormularies.map(this.renderFormulary)}
							className="formularies-list"
						/>
					) : (
						<Placeholder
							icon="formulary"
							text="Adding your prescriptions will help us compare costs between plans."
							height={70}
						/>
					)}
				</div>
			</div>
		);
	}
}
