import React, {Component} from "react";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import {
	NewExistingPlanWrapper,
	RadioInputCentered,
} from "./NewExistingPlanHelpers";

export class Tobacco extends Component {
	constructor(props) {
		super(props);

		props.setMaxWidth(400);
		props.setBottomNextButtonText("Next");

		const selectedRadio =
			localStorage.getItem(LOCAL_STORAGE_CONSTS.PAGES.INTRO_TOBACCO) || "";

		this.state = {selectedRadio};

		this.options = [
			{value: "yes", label: "Yes"},
			{value: "no", label: "No"},
		];
	}

	componentDidUpdate() {
		const {setNextComponent} = this.props;
		const {selectedRadio} = this.state;

		if (selectedRadio) setNextComponent("NiceToMeet");

		localStorage.setItem(
			LOCAL_STORAGE_CONSTS.PAGES.INTRO_TOBACCO,
			selectedRadio
		);
	}

	render() {
		const {selectedRadio} = this.state;

		return (
			<NewExistingPlanWrapper>
				<h4>Do you use tobacco?</h4>
				<RadioInputCentered
					value={selectedRadio}
					onChange={(selectedRadio) => this.setState({selectedRadio})}
					options={this.options}
				/>
			</NewExistingPlanWrapper>
		);
	}
}
