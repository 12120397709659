import React, {Component} from "react";
import PropTypes from "prop-types";
import {FormStyled} from "./FormHelpers";

export default class Form extends Component {
	onSubmitWrapper = (e) => {
		const {onSubmit} = this.props;

		e.preventDefault();
		if (onSubmit) onSubmit(e.target);
	};

	render() {
		const {className, children} = this.props;

		let classes = [className, "form-group"];

		const hiddenStyle = {display: "none"};

		return (
			<FormStyled
				children={children}
				className={classes.join(" ")}
				onSubmit={this.onSubmitWrapper}
			>
				{children}
				<input type="submit" style={hiddenStyle} />
			</FormStyled>
		);
	}

	onSubmitWrapper = (e) => {
		const {onSubmit} = this.props;

		e.preventDefault();
		if (onSubmit) onSubmit(e.target);
	};
}

Form.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	onSubmit: PropTypes.func,
};

Form.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	onSubmit: PropTypes.func,
};

Form.defaultProps = {
	className: "",
};
