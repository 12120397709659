import React from "react";

export const provider = ({color, height, width}) => (
	<svg
		viewBox="26.502 25.61 69.397 93.247"
		height={height}
		width={width || "69.397"}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M45.375 93.814c.882 0 1.564.667 1.564 1.54 0 .879-.682 1.558-1.565 1.558-.883 0-1.576-.68-1.576-1.558 0-.873.693-1.54 1.576-1.54h.001zM26.549 95.36l-.047 23.094c0 .855 68.999.083 68.999.083.866 0 .048-23.177.048-23.177 0-11.13-9.116-20.151-20.384-20.151-2.122-.03-2.128 3.128-.006 3.105v11.012c-3.568.727-6.26 3.849-6.26 7.586v1.547c.042 2.015 3.089 2.015 3.136 0v-1.547c0-2.6 2.063-4.645 4.695-4.645 2.637 0 4.7 2.044 4.7 4.645v1.547c-.041 2.109 3.189 2.109 3.142 0v-1.547c0-3.743-2.703-6.871-6.277-7.592V78.606c8.067 1.441 14.118 8.342 14.118 16.754l.126 20.269-62.968-.094.114-20.175c0-8.412 6.051-15.313 14.113-16.754v12.396c-1.814.644-3.136 2.355-3.136 4.352 0 2.548 2.134 4.657 4.712 4.657 2.579 0 4.7-2.109 4.7-4.657 0-2.003-1.321-3.714-3.135-4.358V78.314c2.122.029 2.122-3.129 0-3.105-11.268 0-20.39 9.021-20.39 20.151zm28.22-24.715a14.22 14.22 0 006.283 1.47c2.252 0 4.38-.538 6.277-1.464v5.612l-6.283 8.682-6.277-8.682v-5.618zm9.419-23.326c1.173 1.594 3.265 3.093 6.277 3.093h1.564v7.756c0 6.04-4.872 10.849-10.977 10.849-6.111 0-10.977-4.81-10.977-10.849v-7.756h7.841c3.012 0 5.104-1.5 6.271-3.093h.001zm-.078-4.65a1.56 1.56 0 00-1.416 1.06s-1.269 3.584-4.778 3.584h-9.412c-.871 0-1.565.698-1.565 1.553v9.302c0 4.124 1.82 7.815 4.694 10.369v8.23c.006.322.113.639.303.902l7.836 10.843c.628.861 1.926.861 2.548 0l7.849-10.842c.19-.264.297-.58.29-.902v-8.224c2.881-2.554 4.707-6.25 4.707-10.375v-9.302c0-.855-.694-1.553-1.565-1.553h-3.135c-3.498 0-4.796-3.584-4.796-3.584a1.559 1.559 0 00-1.558-1.06l-.002-.001zM61.047 25.61c-12.133 0-21.949 9.707-21.949 21.703v24.305c-.041 2.109 3.183 2.109 3.136 0V47.313c0-10.327 8.363-18.604 18.813-18.604 10.456 0 18.825 8.277 18.825 18.604v24.305c0 2.068 3.136 2.068 3.136 0V47.313c-.001-11.996-9.823-21.703-21.962-21.703h.001z"
			fill={color}
		/>
	</svg>
);
