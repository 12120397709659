import React, {Component} from "react";
import styled from "styled-components";
import Drawer from "./index";
import Button from "ui/Button";

const Wrapper = styled.div`
	line-height: 32px;
`;

const DrawerFooter = styled.div`
	margin-top: 75px;
	text-align: center;
`;

const ButtonRow = styled.div`
	display: flex;
	justify-content: center;
`;

export class DrawerContentWrapper extends Component {
	render() {
		const {children} = this.props;

		return (
			<Wrapper>
				{children}
				<DrawerFooter>
					<ButtonRow>
						<Button onClick={Drawer.hide} text="Close" active />
					</ButtonRow>
				</DrawerFooter>
			</Wrapper>
		);
	}
}
