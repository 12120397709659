import React, {Component} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Drawer from "./index";

const DrawerUnderlineStyled = styled.span`
	border-bottom: 1.5px dashed var(--vibrant-mint);
	padding-bottom: 5px;
	cursor: pointer;
`;

export default class DrawerUnderline extends Component {
	onClickWrapper = () => {
		const {drawerContent, drawerTitle} = this.props;

		Drawer.show({
			title: drawerTitle,
			content: drawerContent,
		});
	};

	render() {
		const {text} = this.props;

		return (
			<DrawerUnderlineStyled children={text} onClick={this.onClickWrapper} />
		);
	}
}

DrawerUnderline.propTypes = {
	text: PropTypes.node.isRequired,
	drawerContent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
		.isRequired,
	drawerTitle: PropTypes.node.isRequired,
};
