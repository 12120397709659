import React, {Component} from "react";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import CheckboxGroup from "ui/Checkbox";
import {MedicareRetireHeader} from "./MedicareRetireHelpers";

export class MedicareRetire extends Component {
	constructor(props) {
		super(props);

		props.setMaxWidth(600);

		const selectedBoxes = localStorage.getItem(
			LOCAL_STORAGE_CONSTS.PAGES.INTRO_MEDICARE_RETIRE
		);

		if (selectedBoxes) {
			this.state = {selectedBoxes: JSON.parse(selectedBoxes)};
			props.setBottomNextButtonText("Next");
		} else {
			this.state = {selectedBoxes: []};
			props.setBottomNextButtonText("Skip");
		}

		this.options = [
			{
				value: "medicare_last_6",
				label:
					"I already signed up for Medicare Part B, and I signed up in the last 6 months.",
			},
			{
				value: "medicare_next_3",
				label:
					"I will be signing up for Medicare Part B within the next 3 months.",
			},
			{
				value: "retire_last_6",
				label: "I have stopped full-time work within the past 6 months.",
			},
			{
				value: "retire_next_3",
				label: "I plan to stop full-time work within the next 3 months.",
			},
		];
	}

	componentDidUpdate(prevProps, prevState) {
		const {setNextComponent, setBottomNextButtonText} = this.props;
		const {selectedBoxes} = this.state;

		if (selectedBoxes) {
			setNextComponent("Tobacco");
		}

		if (prevState.selectedBoxes.length === 0 && selectedBoxes.length === 1)
			setBottomNextButtonText("Next");
		else if (prevState.selectedBoxes.length === 1 && selectedBoxes.length === 0)
			setBottomNextButtonText("Skip");

		localStorage.setItem(
			LOCAL_STORAGE_CONSTS.PAGES.INTRO_MEDICARE_RETIRE,
			JSON.stringify(selectedBoxes)
		);
	}

	onChangeWrapper = (val) => {
		const {selectedBoxes} = this.state;

		if (selectedBoxes.includes(val))
			this.setState({selectedBoxes: selectedBoxes.filter((f) => f !== val)});
		else {
			selectedBoxes.push(val);
			this.setState({selectedBoxes});
		}
	};

	render() {
		const {selectedBoxes} = this.state;
		return (
			<div>
				<MedicareRetireHeader>
					<h4>Do any of these apply to you?</h4>
					<div>(Check any that apply)</div>
				</MedicareRetireHeader>
				<CheckboxGroup
					selected={selectedBoxes}
					options={this.options}
					onChange={this.onChangeWrapper}
					type="column"
				/>
			</div>
		);
	}
}
