import React, {Component} from "react";
import styled from "styled-components";
import Waves from "ui/Waves";

const Container = styled.div`
	margin-top: 100px;
`;

const Header = styled.h4`
	font-weight: bold;
	text-align: center;
`;

const Body = styled.div`
	line-height: 32px;
`;

export class JourneyStart extends Component {
	constructor(props) {
		super(props);

		props.setNextComponent("FirstName");
		props.setMaxWidth(670);
	}
	render() {
		return (
			<>
				<Container>
					<Header>Your journey starts here.</Header>
					<Body>
						We find plans by asking you about your priorities, so that we can
						become your advocates for what matters most to you.
						<br />
						<br />
						We use our technology to create a list of plans tailored to your
						individual needs – and then, one of our agents (a real person, not a
						robot) will help you enroll in the right plan over the phone.
					</Body>
				</Container>
				<Waves />
			</>
		);
	}
}
