import React, {Component} from "react";
import RadioInput from "ui/RadioInput";
import LOCAL_STORAGE_CONSTS from "util/constants/localStorage";
import {NewExistingPlanWrapper} from "./NewExistingPlanHelpers";

export class NextExistingPlan extends Component {
	constructor(props) {
		super(props);

		props.setMaxWidth(400);

		const name = localStorage.getItem(LOCAL_STORAGE_CONSTS.FIRST_NAME) || "";
		const selectedRadio =
			localStorage.getItem(LOCAL_STORAGE_CONSTS.PAGES.INTRO_FIRST_EXISTING) ||
			"";

		this.state = {name, selectedRadio};

		this.options = [
			{value: "first", label: "Enroll in my first Medicare plan"},
			{value: "existing", label: "Change my existing plan"},
		];
	}

	componentDidUpdate() {
		const {setNextComponent} = this.props;
		const {selectedRadio} = this.state;

		if (selectedRadio) setNextComponent("BasicInfo");

		localStorage.setItem(
			LOCAL_STORAGE_CONSTS.PAGES.INTRO_FIRST_EXISTING,
			selectedRadio
		);
	}

	render() {
		const {name, selectedRadio} = this.state;

		return (
			<NewExistingPlanWrapper>
				<h2>{`Hi ${name}!`}</h2>
				<h4>How can we help?</h4>
				<RadioInput
					value={selectedRadio}
					onChange={(selectedRadio) => this.setState({selectedRadio})}
					options={this.options}
				/>
			</NewExistingPlanWrapper>
		);
	}
}
