import styled, {css} from "styled-components";

export const RadioGroupWrapper = styled.div`
	display: flex;

	${({type}) =>
		type === "column" &&
		css`
			flex-direction: column;
			align-items: flex-start;

			label {
				padding: 25px 0;
			}
		`}

	${({type}) =>
		type === "inline" &&
		css`
			flex-wrap: wrap;

			label {
				padding: 12px 19px;
			}
		`}
`;

export const RadioInputWrapper = styled.label`
	font-weight: ${({active}) => (active ? "bold" : "normal")};
	cursor: pointer;
	display: flex;

	input {
		display: none;
	}
`;

export const RadioFillDot = styled.span`
	height: 30px;
	width: 30px;
	border: 3px solid var(--sky-blue);
	border-radius: 50%;
	margin-right: 25px;
	content: " ";
	background-color: white;

	${(props) =>
		props.active &&
		css`
			::after {
				display: block;
				width: 20px;
				height: 20px;
				margin: 2px;
				border-radius: 50%;
				background: var(--cerulean);
				content: " ";
			}
		`}
`;
