import React from "react";
import styled from "styled-components";

const ViewportWidth = styled.div`
	position: absolute;
	left: 0;
	width: 100vw;
	background: white;
`;

const DividerStyled = styled.div`
	background-color: var(--dark-mint);
	width: 100%;
	height: 14px;
`;

const TriangleStyled = styled.div`
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 20px solid var(--dark-mint);
	position: relative;
	transition-duration: 0.5s;
	margin-left: calc(${({index}) => (100 * index) / 4}% + 104px);
`;

const MoreInfo = styled.div`
	max-width: 800px;
	margin: auto;
	padding: 45px 0;
`;

// Extra 250px needed at bottom to account for <ViewportWidth> as it's position absolute
export const PlanAspectsWrapper = styled.div`
	margin-bottom: 250px;
`;

export const PlanAspectsDivider = (props) => {
	return (
		<div>
			<TriangleStyled index={props.index} />
			<ViewportWidth>
				<DividerStyled />
				<MoreInfo children={props.content} />
			</ViewportWidth>
		</div>
	);
};
