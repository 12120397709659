import styled from "styled-components";

export const NavigationButtonsTop = styled.div`
	margin-top: 25px;
	display: flex;
	height: 60px;

	.prev {
		margin-right: auto;
	}

	.next {
		margin-left: auto;
	}
`;

export const NavigationButtonsBottom = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 50px;
`;

// -75px margin-top added for Hear.com Demo to force QuestionFlow content to be inline with navbar buttons. Find a cleaner solution.
export const QuestionFlowWrapper = styled.div`
	margin: -75px auto 0 auto;

	max-width: ${({$maxWidth}) => $maxWidth}px;

	.page-header {
		text-align: center;
		max-width: 600px;
	}

	@media (min-width: 640px) {
		.page-header {
			margin-bottom: 66px;
		}
	}
`;
