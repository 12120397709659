import React from "react";
import styled from "styled-components";

const IconBackdropStyled = styled.div`
	padding: 8px 0 0 2px;
	width: ${({$width}) => $width}px;
	height: ${({$width}) => $width}px;
	border-radius: 50%;
	background-color: ${({$color}) => $color};
	overflow: hidden;
`;

export default ({width, color, children}) => {
	return (
		<IconBackdropStyled $width={width} $color={color} children={children} />
	);
};
